<template>
    <div v-observe-visibility="isVisible" class="memod-list-container">
        <report-modal @reported-entity="handleReportedMemo" />
        <report-thanks-modal />
        <delete-memo-modal @delete-memo="deleteMemo" />
        <div
            v-infinite-scroll="fetchList"
            infinite-scroll-immediate-check="false"
            infinite-scroll-disabled="fetchingList"
            infinite-scroll-distance="400"
            class="memod-list"
        >
            <div
                v-masonry="masonryListName"
                transition-duration=".3s"
                item-selector=".item"
                :gutter="30"
                :fit-width="true"
                :origin-top="false"
                :destroy-delay="2000"
            >
                <memod-card
                    v-for="(memod, index) in feedList"
                    :key="`${ memod.id }-${ index }`"
                    v-masonry-tile
                    :select-mode="selectMode"
                    :selected-memo="memod.id == selectedMemo.id"
                    class="item"
                    :memod="memod"
                    @toggled-like="(data) => toggleMemodLike(data, memod.id)"
                    @toggled-save="(is_saved) => toggleMemodSave(is_saved, memod.id)"
                    @toggled-follow="(is_following) => toggleMemodWriterFollow(is_following, memod.id)"
                    @selected-memo="handleSelectedMemo"
                />
            </div>
        </div>
        <div v-if="!feedList.length && firstAttemptToFetchData" class="not-found-memod-list">
            <div class="icon">
                <img :src="noMemosFoundMessage.icon">
            </div>
            <h5>{{ noMemosFoundMessage.title }}</h5>
            <p>{{ noMemosFoundMessage.text }}</p>
        </div>
        <fullscreen-loader v-if="fetchingList" />
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import infiniteScroll from "vue-infinite-scroll";
import { mapGetters } from "vuex";

export default {
    name: "MemodList",
    components: {
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue"),
        MemodCard: () => import(/* webpackChunkName: "memod-card" */ "@/components/molecules/memod-card/"),
        DeleteMemoModal: () => import(/* webpackChunkName: "delete-memo-modal" */ "@c/organisms/modals/delete-memo-modal"),
        ReportModal: () => import(/* webpackChunkName: "report-modal" */ "@/components/molecules/report/report-modal.vue"),
        ReportThanksModal: () => import(/* webpackChunkName: "report-thanks-modal" */ "@/components/molecules/report/thanks-modal.vue")
    },
    directives: {
        infiniteScroll
    },
    props: {
        useApiV2: {
            type: Boolean,
            default: false
        },
        selectMode: {
            type: Boolean,
            default: false
        },
        paginationFormat: {
            type: Boolean,
            default: true
        },
        listEndpoint: {
            type: String,
            required: true
        },
        noMemosFoundMessage: {
            type: Object,
            default() {
                return {
                    icon: require("@assets/img/icons/feed.svg"),
                    title: "Nothing found",
                    text: "Sorry nothing found."
                }
            }
        }
    },
    data() {
        return {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            noDataLeft: false,
            fetchingList: false,
            feedList: [],
            firstAttemptToFetchData: false,
            feedListPagination: {
                limit: 25,
                page: null,
                total_pages: 1
            },
            selectedMemo: {}
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn",
            isReloadFollowing: "Application/isReloadFollowing"
        }),
        masonryListName() {
            if (this.$parent.$options.name) {
                return `${this.$parent.$options.name}-list`
            } else {
                return `${this.$route.name}`
            }
        }
    },
    watch: {
        isReloadFollowing: {
            immediate: true,
            handler() {
                if (this.$store.state.Application.isReloadFollowing) {
                    this.getData();
                }
            }
        },
        listEndpoint: {
            immediate: true,
            handler() {
                if (!this.$store.state.Application.isReloadFollowing) {
                    this.getData();
                }
            }
        }
    },

    methods: {
        isVisible(isVisible) {
            if (isVisible) {
                this.$redrawVueMasonry(this.masonryListName);
            }
        },
        async getData() {
            this.resetMemodList();
            this.handleSelectedMemo({});
            this.fetchList();
        },
        resetMemodList() {
            this.firstAttemptToFetchData = false;
            this.feedList = [];
            this.feedListPagination.page = null;
            this.feedListPagination.total_pages = 1;
        },
        fetchList() {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend

            const limit = this.feedListPagination.limit;
            const nextPage = this.feedListPagination.page ? (this.feedListPagination.page + 1) : 1;

            if (Number(nextPage) <= this.feedListPagination.total_pages) {
                this.fetchingList = true;
                const axiosVersion = this.useApiV2 ? axiosV2 : axios;
                axiosVersion.get(`${this.listEndpoint}${this.paginationFormat ? "&format=true" : ""}&light=true&limit=${limit}&page=${nextPage}`).then((response) => {
                    // This is temporary needed due to an inconsistency in the backend pagination,
                    // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
                    // backend after there's no memods left in the data array that is being returned by the backend
                    if (response.data.hasOwnProperty("data") && !response.data.data.length) {
                        this.noDataLeft = true;
                        return;
                    }

                    const memos = response.data.data ? response.data.data : response.data.results;
                    const page = response.data.page ? response.data.page : response.data.meta.page.current;
                    const totalPages = response.data.total_pages ? response.data.total_pages : response.data?.meta?.page?.total_pages;

                    for (var i = 0; i < memos.length; i++) {
                        memos[i].is_reported = 0;
                        this.feedList.push({ ...memos[i] });
                        this.feedListPagination.page = page;
                        this.feedListPagination.total_pages = totalPages;
                        this.fetchingList = false;
                    }

                }).finally(() => {

                    if (!this.firstAttemptToFetchData) {
                        setTimeout(() => {
                            this.$redrawVueMasonry(this.masonryListName);
                        }, 300);
                    } else {
                        this.$redrawVueMasonry(this.masonryListName);
                    }

                    this.firstAttemptToFetchData = true;
                    this.fetchingList = false;
                });
            }
        },
        toggleMemodLike(data, memoId) {
            const likedMemo = this.feedList.findIndex(memo => memo.id == memoId);
            this.feedList[likedMemo].is_liked = data.isLiked;
            this.feedList[likedMemo].total_likes = data.totalLikes;
        },
        toggleMemodSave(isSaved, memoId) {
            const savedMemo = this.feedList.findIndex(memo => memo.id == memoId);
            this.feedList[savedMemo].is_saved = isSaved;
        },
        toggleMemodWriterFollow(isFollowing, memoId) {
            const memoFollowToggled = this.feedList.findIndex(memo => memo.id == memoId);
            this.feedList[memoFollowToggled].writer.is_following = isFollowing;
        },
        deleteMemo(memoId) {
            const memoToDelete = this.feedList.findIndex(memo => memo.id == memoId);
            this.feedList.splice(memoToDelete, 1);
            this.$emit("delete-memo", memoId);
        },
        handleSelectedMemo(memo) {
            this.selectedMemo = memo;
            this.$emit("selected-memo", memo);
        },
        handleReportedMemo(memoId) {
            const reportedMemoIndex = this.feedList.findIndex(memo => memo.id === memoId);
            this.feedList[reportedMemoIndex].is_reported = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.memod-list-container {
    position: relative;

    .loader {
        position: relative;
        background-color: transparent;
        height: 200px;

        /deep/ img {
            width: 100px !important;
        }
    }

    .memod-list {
        display: flex;
        justify-content: center;
        padding-top: 40px;

        .memod-card,
        .memod-card-serialized {
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }

    .not-found-memod-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 230px;
        margin: 0 auto;
        margin-top: 80px;
        text-align: center;

        .icon {
            width: 72px;
            height: 72px;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 37px;
            }
        }

        h5 {
            font-size: 18px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: rgba(155, 155, 162, 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
</style>
